<template>
  <div class="flex flex-row gap-2">
    <Badge
      v-if="isNew"
      size="small"
      type="default"
      :label="$t('badge_labels.neu')"
    ></Badge>
    <Badge v-if="isSale" size="small" type="warning" :label="saleLabel"></Badge>
    <Badge
      v-if="isSustainable"
      size="small"
      type="info"
      :label="$t('badge_labels.sustainable')"
    ></Badge>
    <Badge
      v-if="isSoldOut"
      size="small"
      type="info"
      :label="$t('badge_labels.sold_out')"
      data-testid="sold-out-badge"
    ></Badge>
    <ProductPromotionBadges
      v-if="showPromotionBadge"
      :product="product"
      :is-full-width="false"
      class="ml-1"
    />
  </div>
</template>

<script setup lang="ts">
import { type Product, type Variant } from '@scayle/storefront-nuxt'
import Badge from '~/components/ui/badge/Badge.vue'
import { useProductPrice } from '~/composables/useProductPrice'
import { PRODUCT_LIFECYCLE_STATUS_PUBLISHED } from '~/constants/product'

const props = defineProps({
  product: {
    type: Object as PropType<Product>,
    required: true,
  },
  selectedVariant: {
    type: Object as PropType<Variant>,
    default: undefined,
  },
  showPromotionBadge: {
    type: Boolean,
    default: false,
  },
})

const { getCombinedProductPrice } = await useProductPrice()

// Cant be determined for Contact Lenses (would always be true)
const isSoldOut = computed(() => {
  if (
    isContactLens(props.product) &&
    getProductLifecycle(props.product) === PRODUCT_LIFECYCLE_STATUS_PUBLISHED
  ) {
    return false
  }
  if (props.selectedVariant) {
    return !isVariantAvailable(props.product, props.selectedVariant)
  }
  return !isProductAvailable(props.product)
})

const isNew = computed(() => props.product.isNew)

const isSustainable = computed(() => isProductSustainable(props.product))

const isSale = computed(() => {
  const price = props.selectedVariant
    ? props.selectedVariant.price
    : getCombinedProductPrice(props.product)
  if (price.appliedReductions.length === 0) {
    return false
  }

  const highestReduction = price.appliedReductions.reduce(
    (prevReduction, curReduction) =>
      curReduction.amount.relative > prevReduction.amount.relative
        ? curReduction
        : prevReduction,
  )
  // for low reductions bapi returns 0 as relative reduction
  return highestReduction.amount.relative === 0
    ? '%'
    : highestReduction.amount.relative
})

const saleLabel = computed(() =>
  typeof isSale.value === 'number'
    ? `-${Math.round(isSale.value * 100)}%`
    : '%',
)
</script>

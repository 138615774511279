<template>
  <div
    v-if="enabled"
    ref="root"
    :class="visible ? '' : 'hidden'"
    :data-bv-show="show"
    :data-bv-product-id="id"
  ></div>
</template>
<script lang="ts">
const AUTO_HIDE: string[] = ['inline_rating', 'rating_summary']
const DISABLED_ROUTES = ['/']

export default defineComponent({
  name: 'BazaarVoice',

  props: {
    show: {
      type: String as PropType<'reviews' | 'inline_rating' | 'rating_summary'>,
      default: '',
    },
    id: {
      type: [Number, String] as PropType<number | string>,
      required: true,
    },
  },

  setup(props) {
    // const { $route } = useContext()
    const $currentShop = useCurrentShop()
    const route = useRoute()
    const root = ref<Element>()
    const visible = ref<boolean>(!AUTO_HIDE.includes(props.show))
    const observer = ref<MutationObserver>()

    const enabled =
      !DISABLED_ROUTES.includes(route.path) &&
      $currentShop.value.bazaarVoiceScript

    const script = enabled
      ? [
          {
            src: $currentShop.value.bazaarVoiceScript,
            async: true,
            defer: true,
            hid: 'bazaarvoice-head-script',
            once: true,
          },
        ]
      : []

    useHead(() => ({
      script,
    }))

    onMounted(() => {
      // Callback function to execute when mutations are observed
      const callback = (_mutationList: MutationRecord[]) => {
        // We check for textContent to be sure the widget has rendered
        if (root.value?.textContent) {
          // try to find the reviewCount itemprop
          const reviewCount: HTMLMetaElement | null = root.value?.querySelector(
            '[itemprop="reviewCount"]',
          )
          // reviews don't contain the itemprop reviewCount
          // but we can search for the .bv-rating-none element instead
          const fallback =
            props.show !== 'reviews' ||
            root.value?.querySelector('.bv-rating-none')
              ? '0'
              : '1'

          // Hide any ratings without reviews
          visible.value = parseFloat(reviewCount?.content ?? fallback) !== 0
        }
      }

      // Only hide hide empty elements which are not always visible
      if (root.value && AUTO_HIDE.includes(props.show)) {
        // Create an observer instance linked to the callback function
        observer.value = new MutationObserver(callback)
        // Start observing the target node for configured mutations
        observer.value.observe(root.value, {
          attributes: false,
          childList: true,
          subtree: false,
        })
      }
    })

    onUnmounted(() => {
      if (observer.value) {
        observer.value.disconnect()
      }
    })

    return {
      enabled,
      visible,
      root,
    }
  },
  head() {
    return {}
  },
})
</script>

<style scoped>
div :deep(.bv-rating-stars-container.bv-rating-none) {
  display: none !important;
}
</style>

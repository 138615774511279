<template>
  <ClientOnly>
    <button
      class="transition-opacity duration-200 ease-in-out"
      :class="{
        'pointer-events-none opacity-30': isUpdating,
      }"
      data-testid="pdp_button_favorite"
      @click="toggleWishlistItem"
    >
      <div class="sr-only">
        {{
          isInWishlist
            ? $t('basket_card.remove_from_wishlist_label')
            : $t('basket_card.add_to_wishlist_label')
        }}
      </div>
      <div
        class="relative"
        :class="[
          {
            'flex h-[42px] w-[82px] items-center justify-center rounded border border-gray-500':
              isBoxLayout,
            'flex size-6 items-center justify-center': !isBoxLayout,
          },
        ]"
      >
        <IconFielmannFavoriteInactive
          v-if="!isInWishlist"
          data-testid="list_button_favorite_inactive"
          :class="classNames"
        />
        <IconFielmannClose
          v-else-if="isInWishlist && showCloseIcon"
          data-testid="list_button_favorite_active"
          :class="classNames"
        />
        <IconFielmannFavoriteActive
          v-else
          data-testid="list_button_favorite_active"
          :class="classNames"
        />
      </div>
    </button>
    <template #fallback>
      <IconFielmannFavoriteInactive
        data-testid="list_button_favorite_inactive"
        :class="classNames"
      />
    </template>
  </ClientOnly>
</template>

<script setup lang="ts">
import { type Product, type Variant } from '@scayle/storefront-nuxt'

const props = defineProps({
  product: {
    type: Object as PropType<Product>,
    required: true,
  },
  variant: {
    type: Object as PropType<Variant>,
    default: undefined,
    required: false,
  },
  showCloseIcon: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: '6',
  },
  layout: {
    type: String,
    default: 'default',
    validator: (val: string) => ['default', 'box'].includes(val),
  },
})

const classNames = computed(
  () => `inline-block w-${props.size} h-${props.size}`,
)
const isBoxLayout = props.layout === 'box'

const { isUpdating, isInWishlist, toggleWishlistItem } = await useFimWishlist({
  product: props.product,
  variant: props.variant,
})
</script>

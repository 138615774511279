<template>
  <button
    v-if="isSyteEnabled && variantImageUrl"
    class="syte-discovery flex items-center justify-center"
    :class="{
      'syte-discovery group inline-flex cursor-pointer items-center justify-center gap-2 whitespace-nowrap rounded-lg border border-neutral-30 bg-white fill-current px-2 py-3 text-base font-normal leading-[1.375rem] tracking-normal text-primary transition duration-100 ease-linear hover:border-neutral-50 focus:outline-none disabled:pointer-events-none disabled:opacity-20':
        type === 'button',
    }"
    :data-image-src="variantImageUrl"
    :data-sku="variantOrProductId"
    :data-placement="placement"
    :data-tracking-label="`Similar Items | ${placement}`"
    :aria-label="$t('pdp.shop_similars')"
    @click="trackClick"
  >
    <IconFielmannMagnifierBordered class="pointer-events-none size-5" />
    <template v-if="type === 'button'">
      {{ $t('pdp.shop_similars') }}
    </template>
  </button>
</template>

<script setup lang="ts">
import type { ProductImage } from '@scayle/storefront-core'

// images/46dc81e1d27819d030f8b2ebc519609c.png
// https://fim-prev.cdn.aboutyou.cloud/images/46dc81e1d27819d030f8b2ebc519609c.png?trim=1&brightness=1&width=384&height=512&quality=85&bg=FFFFFF

const props = defineProps({
  variantOrProductId: {
    type: Number as PropType<number>,
    required: true,
  },
  productImage: {
    type: Object as PropType<ProductImage>,
    required: true,
  },
  placement: {
    type: String as PropType<SyteValidPlacement>,
    required: true,
  },
  type: {
    type: String as PropType<'icon' | 'button'>,
    default: 'icon',
  },
})

const { isSyteEnabled } = await useFeatureToggles()
const { trackButtonClick } = await useTrackingEvents()
const image = useImage()

const trackClick = (event: Event) => {
  const element = event?.target as HTMLButtonElement
  trackButtonClick(element)
}

const variantImageUrl = computed(() =>
  image(props.productImage.hash, { trim: 1 }, { provider: 'fim' }),
)
</script>
